@import url(https://fonts.googleapis.com/css?family=Slabo+27px|Gentium+Book+Basic&subset=latin,latin-ext);
* {
    box-sizing: border-box;
}

#header h2 {
    font-family: 'Gentium Book Basic', serif;
    font-size: 60px;
    margin: 0;
    display: inline;
}

#header p {
    display: inline;
}

#footer {
    padding-top: 1em;
}

form, .form {
    margin: 20px 0 15px 0;
    display: block;
    text-align: center;
}

button {
    -webkit-appearance:none;
    background: lightgray;
    color: inherit;
    border: 1px solid gray;
    padding: 0;
    cursor: pointer;
    outline: none;
}

select {
    -webkit-appearance:none;
    -webkit-border-radius: 0;
    background: white;
    color: inherit;
    border: 1px solid gray;
    padding: 0;
    cursor: pointer;
    outline: none;
}


html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

body {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

body {
    background: #e1e1e1; /* Old browsers */ /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
    background: linear-gradient(to bottom,  #e1e1e1 0%,#ffffff 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e1e1', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    background-size: 40px 40px;
    background-repeat: repeat-x;

    overflow: hidden;

}

#editor-wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#editor {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.editorWindow {
    width: 100%;
    flex-grow: 1;
    margin: 10px auto;
}

.current-statement {
    background: #7883ff;
    position: absolute;
}

.sicp-editor-toolbar {
    display: flex;
}

.sicp-editor-button {
    margin: 5px;
    padding: 5px;
}

.sicp-editor-select-sample {
    margin-left: auto;
    padding: 5px;
}

#editor-bottom {
    height: 200px;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
}

.editorWindow {
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    margin: 10px 0 0 0;
}

.sicp-box {
    
    font-family: 'Lucida Grande', sans-serif;
    font-size: 12px;
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    margin: 0;
    padding: 0;
    background: #f3f3f3;

    margin: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;

    overflow: auto;
}

.sicp-box {
    cursor: default;
    display: flex;
    flex-direction: column;
}

.sicp-box-tab-title {
    display: inline-block;
    margin: 0;
    padding: 0;
}
.sicp-box:last-child {
    border-right: 1px solid lightgray;
}

.sicp-box-tab-title {
    padding: 6px;
    margin: 4px 0 0 4px;
    cursor: default;
}

.sicp-box-tab-content {
    border-top: 1px solid lightgray; 
    background: white;
    flex-grow: 1;
    overflow: auto;
}
.sicp-box-current-tab .sicp-box-tab-title {
    background: white;
}

.sicp-tree-node-title {
    margin: 0;
    padding: 0;
    background: #ebebeb;
    border-bottom: 1px solid #dadada;
    padding: 4px;
    
}


.sicp-tree-node-title:before {
    content:'▼ ';
    font-size: 10px;
    color: #777777;
    
}

.sicp-tree-node-collapsed .sicp-tree-node-title:before {
    content:'► ';
    font-size: 10px;
    color: #777777;

}
.sicp-tree-node-collapsed .sicp-tree-node-content {
    display: none;	
}

.sicp-tree-node-content div {
    display: flex;
    flex-direction: row;
}

.sicp-scope-empty {
    text-align: center;
    color: #777777;
}
#bottom-left-box {
    width: 70%;
}

#bottom-right-box{
    width: 30%;
}

.sicp-variable-name, .sicp-variable-value {
    padding: 4px;
    flex: 1 1;
    overflow: auto;
    border-bottom:1px solid lightgray;
}
.sicp-variable-name {
    border-right:1px solid lightgray;
}

.sicp-stack-frame p {
    margin:0;
    padding: 4px;
}

.sicp-stack-frame-current {
    font-weight: bold;
}

.sicp-stack-frame:hover {
    background: lightgray;
}

#output-content {
    padding: 4px;
}

